//
//	THIS FILE IS GENERATED BY SharedConstants. DO NOT MODIFY
//	see /horizon_ad_tracking/lib/ad_tracking_constants.dart
//


export const EVENT_PARAM_NAME = 'event_name';	
export const EVENT_PARAM_VOLUME = 'volume';	
export const EVENT_NAME_START = 'start';	
export const EVENT_PARAM_DURATION = 'duration';	
export const EVENT_NAME_IMPRESSION = 'impression';	
export const EVENT_NAME_VIEWABLE_IMPRESSION = 'ViewableImpression';	
export const EVENT_NAME_CLOSE_LINEAR = 'closeLinear';	
export const EVENT_NAME_CLICK_TRACKING = 'clickTracking';	
export const EVENT_NAME_PAUSE = 'pause';	
export const EVENT_NAME_RESUME = 'resume';	
export const EVENT_NAME_FIRST_QUARTILE = 'firstQuartile';	
export const EVENT_NAME_MID_POINT = 'midpoint';	
export const EVENT_NAME_THIRD_QUARTILE = 'thirdQuartile';	
export const EVENT_NAME_COMPLETE = 'complete';	
export const EVENT_NAME_VOLUME_CHANGE = 'volumeChange';
export const EVENT_NAME_PLAYER_EXPAND = 'playerExpand';	
export const EVENT_NAME_PLAYER_COLLAPSE = 'playerCollapse';	
export const EVENT_NAME_PROGRESS = 'progress';	
export const EVENT_NAME_REWIND = 'rewind';	
export const CREATIVE_VIEW = 'creativeView';	
export const OVERLAY_VIEW_DURATION = 'overlayViewDuration';	
export const OTHER_AD_INTERACTION = 'otherAdInteraction';	
export const CLOSE = 'close';	
export const MINIMIZE = 'minimize';
export const ERROR = 'error';	
export const EVENT_NAME_BUFFER_START = 'bufferStart';
export const EVENT_NAME_BUFFER_FINISH = 'bufferFinish';
export const EVENT_NAME_SKIP = 'skip';

export const ERROR_CODE = 'OM_ERROR';	
export const METHOD_CHANNEL_NAME = 'horizon_ad_tracking';	
export const METHOD_NAME_STARTUP = 'om_startup';	
export const METHOD_NAME_UPDATE_PLAYER_VIEW = 'om_update_player_view';	
export const METHOD_NAME_TRACK_EVENT = 'om_track_event';	
export const METHOD_NAME_SHUTDOWN = 'om_shutdown';	
export const METHOD_PARAM_INSTANCE_ID = 'instanceId';	
export const METHOD_PARAM_JS_RESOURCE_URI = 'jsResourceUri';	
export const METHOD_PARAM_VENDOR_ID = 'vendorId';	
export const METHOD_PARAM_VERIFICATION_PARAMS = 'verificationParams';	
export const METHOD_PARAM_OMID_JS_SERVICE_CONTENT = 'omidJsServiceContent';	
export const METHOD_PARAM_PARTNER_NAME = 'partnerName';	
export const METHOD_PARAM_PARTNER_SDK_OR_APP_VERSION = 'partnerSdkOrAppVersion';	


